<template>
 <the-layout-without-login>
      <form @submit.prevent="reSend" class="mt-4 mb-4">
                        <h4 class="mb-0"><strong>忘記密碼</strong></h4> 
                        <span class="help-block text-muted">系統將重發認證信,請留意信箱!</span> 
                        <!--
                        <CInput placeholder="Username"
                                autocomplete="username">
                            <template #prepend-content>
                                <CIcon name="cil-user" />
                            </template>
                        </CInput>
                        -->
                        
                        <CInput placeholder="請輸入您的 Email" class="mt-5"
                                autocomplete="email"
                                prepend="@" v-model="username" />
                        
                        <br>
                        <button type="submit" class="btn btn-success btn-lg text-white btn-block"><strong>確認</strong></button>  
                    </form>
                     <br /><br />
                            <p class="text-center">
                                  不是會員？  <router-link to="/register"><strong class="text-default">立即註冊</strong></router-link> 
                            </p>  
 </the-layout-without-login> 
</template>

<script>
import TheLayoutWithoutLogin from '../../containers/TheLayoutWithoutLogin.vue'
import dialog from '../../util/dialog';
import axios from 'axios';
import appSettings from '../../util/settings';

export default {  
    components: {
        TheLayoutWithoutLogin 
    }, 
    data() {
        return {
            username:''
        }
    }, 
    methods: {   
        reSendResult(response){   
            var data = response.data;
            if(data.IsSuccess){
                this.$router.push({
                    path:'/login',
                    name:'Login',
                    params:{
                    }
                });

                dialog.showInforDialog("請先至郵箱收信");
            }
            else{
                dialog.showInforDialog(data.Message);
            }
        },
        reSend(){
            var errorMessage = '';
            if(this.username === ''){
                errorMessage += '請輸入您的 Email<br>';
            }

            if(errorMessage === ''){               
                var reSendResult = this.reSendResult;

                axios.post(appSettings.ReSendMailDb, 
                    {
                        userId : this.username
                    })
                    .then(reSendResult)
                    .catch(function (error) { 
                        dialog.showInforDialog(error);
                        console.log(error);
                });
            }
            else{
                 dialog.showInforDialog(errorMessage);
            }
        }
    },
    name: 'Register'
}
</script>
